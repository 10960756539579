import React from "react";

const PrivacyPolicyTr= () =>  {
    return (
        <div className="PrivacyPolicyTr">
            <h1 style={{display: 'flex', justifyContent: 'center'}}>Trauma Relief Kullanım Şartları ve Anlaşmaları</h1>
            <br/><br/>
            <font size ="5">
                <p style={{marginLeft: '20px', marginRight: '20px'}}>
                    Trauma Relief'e hoş geldiniz! Başlamadan önce, uygulamamızın kullanımını düzenleyen şartları dikkatlice gözden geçirmeniz ve anlamanız önemlidir. Trauma Relief'i kullanarak, bu şartlara uymayı kabul edersiniz ve uymamanız durumunda erişiminizin sonlandırılabileceğini kabul edersiniz.
                </p>
                <br/><br/>
                <ol style={{marginLeft: '20px', marginRight: '20px'}}>
                    <li>Amaç ve İşlevsellik:
                        <ul>
                            <li>
                                Trauma Relief, kullanıcılara duygusal olarak rahatsız edici olaylarla başa çıkmalarına yardımcı olmak için çeşitli görevler ve egzersizler sunan bir mobil uygulamadır. Bu egzersizlerin amacı, duygusal rahatlama sağlamaktır.
                            </li>
                        </ul>
                    </li>
                    <li>Travmayı Anlama: 
                        <ul>
                            <li>
                                Travma, insan zihninde çeşitli biçimlerde ortaya çıkabilir. Bunlar arasında benzersiz görüntüler, sesler, düşünceler veya duygular bulunur. Bu deneyimler genellikle algılarımız, düşüncelerimiz ve duygularımızla iç içe geçer ve genel sağlığımızı önemli ölçüde etkiler.
                            </li>
                        </ul>
                    </li>
                    <li>EMDR Terapisi: 
                        <ul>
                            <li>
                                Uygulamamız, sinir sisteminde veya beyindeki travma ile ilgili engelleri açığa çıkarma ve çözme konusunda etkili olduğu kanıtlanmış Göz Hareketleriyle Duyarsızlaştırma ve Yeniden İşleme (EMDR) terapisi tekniklerinden ilham alır.
                            </li>
                        </ul>
                    </li>
                    <li>Kullanıcı Sorumluluğu: 
                        <ul>
                            <li>
                                Trauma Relief, yardım sağlamayı amaçlasa da, iyileşme sürecinin asıl olarak kullanıcının kontrolü altında olduğunu kabul etmek önemlidir. Uygulamamız bir iyileşme garantisi vermez. Kullanıcıların, bireysel ihtiyaçlarına uygun kapsamlı bir tedavi için profesyonel psikiyatrik yardım alması önemle tavsiye edilir.
                            </li>
                        </ul>
                    </li>
                    <li>Tıbbi Feragatname:
                        <ul>
                            <li>
                                Uygulama içinde sağlanan bilgiler, görevler ve egzersizlerin profesyonel tıbbi veya psikolojik tavsiye, teşhis veya tedaviyi yerine koymak amacıyla sunulmadığını belirtiriz. Kullanıcıların, uygulamadan elde edilen bilgilere dayanarak önemli kararlar almaktan kaçınması ve kendi tıbbi danışmanlarına, ruh sağlığı danışmanlarına veya nitelikli tıbbi profesyonellere başvurması önemlidir.
                            </li>
                        </ul>
                    </li>
                    <li>Yaş Gereksinimi: 
                        <ul>
                            <li>
                                Trauma Relief, 13 yaş ve üzeri bireyler tarafından kullanılması için tasarlanmıştır. 18 yaşın altındaki kullanıcıların, ebeveyn gözetiminde veya nitelikli bir ruh sağlığı danışmanının rehberliğinde uygulamaya erişmesi gerekmektedir.
                            </li>
                        </ul>
                    </li> 
                    <li>Yasalara Uygunluk: 
                        <ul>
                            <li>
                                Kullanıcıların, uygulamayı kullanırken yerel yasalara ve geçerli uluslararası yasalara uymaları gerekmektedir. Kullanıcılar, yasalara uyduklarından emin olmalıdır.
                            </li>
                        </ul>
                    </li>
                    <li>Yasaklı Eylemler: 
                        <ul>
                            <li>
                                Kullanıcılar, herhangi bir yasadışı faaliyette bulunmamayı veya uygulamanın kodunu veya içeriğini değiştirme, kopyalama, tersine mühendislik yapma veya başka şekilde müdahale etme gibi yasaklı eylemlerde bulunmamayı kabul ederler.
                            </li>
                        </ul>
                    </li>
                    <li>Sorumluluk Feragatnamesi: 
                        <ul>
                            <li>
                                Trauma Relief'in kullanımı kullanıcının kendi sorumluluğundadır. Uygulamanın kullanımından kaynaklanan her türlü zarardan açıkça feragat ediyoruz. Uygulamaya ilişkin olarak, doğrudan, dolaylı, arızi, sonuç olarak ortaya çıkan veya cezai nitelikteki zararlar da dahil olmak üzere her türlü sorumluluğu reddediyoruz. Doğruluk ve performans da dahil olmak üzere uygulamayla ilgili tüm zımni garantiler, yasaların izin verdiği maksimum ölçüde hariç tutulmuştur.
                            </li>
                        </ul>
                    </li>
                    <li>Veri Gizliliği: 
                        <ul>
                            <li>
                                Uygulamanın işlevselliğini artırmak ve performansını izlemek amacıyla, anonim veriler Google Firebase Analytics SDK aracılığıyla toplanabilir(<a href="http://www.google.com/policies/privacy/">http://www.google.com/policies/privacy/</a>). Bu veriler yalnızca analitik amaçlarla kullanılır ve herhangi bir kişisel tanımlayıcı bilgi içermez. Kullanıcıların gizliliği, geçerli veri koruma yasalarına ve düzenlemelerine uygun olarak sıkı bir şekilde korunur. 
                            </li>
                        </ul>
                    </li> 
                    <li>Değişiklikler ve Güncellemeler:
                        <ul>
                            <li>
                                Trauma Relief, herhangi bir nedenle ve önceden bildirimde bulunmaksızın uygulama veya hizmetlerinde değişiklik yapma hakkını saklı tutar. Kullanıcıların, bu şartları periyodik olarak güncellemeler veya değişiklikler için gözden geçirmesi önerilir.
                            </li>
                        </ul>
                    </li>
                </ol> 
                <br/><br/><br/>
                <p style={{marginLeft: '20px', marginRight: '20px'}}>
                &emsp;&emsp;
                    Trauma Relief'i kullanarak, bu şartları okuduğunuzu, anladığınızı ve kabul ettiğinizi kabul edersiniz. Bu şartların herhangi bir bölümünü kabul etmiyorsanız lütfen uygulamayı kullanmaktan kaçının.
                </p>
                <br/>
                <p style={{marginLeft: '20px', marginRight: '20px'}}>
                &emsp;&emsp;
                    EMDR hakkında daha detaylı bilgi için, linki ziyaret ediniz: <a href="https://www.apa.org/ptsd-guideline/treatments/eye-movement-reprocessing">https://www.apa.org/ptsd-guideline/treatments/eye-movement-reprocessing</a>
                </p>
                <p style={{marginLeft: '20px', marginRight: '20px'}}>
                &emsp;&emsp;
                    Duygusal refah yolculuğunda Trauma Relief'i tercih ettiğiniz için teşekkür ederiz. Bu şartlarla ilgili herhangi bir sorunuz veya endişeniz varsa, lütfen daha fazla yardım için bizimle iletişime geçmekten çekinmeyin.
                </p>
                <p style={{marginLeft: '20px', marginRight: '20px'}}>
                &emsp;&emsp;
                    Uygulama ile ilgili desteğe ihtiyaç duyarsanız lütfen <strong>support@healtheeinc.com</strong> e-posta adresine e-posta yollayınız.</p>
            </font>
        </div>
    );
}

export default PrivacyPolicyTr;